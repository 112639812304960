<template>
  <DefaultSelect
    :label="label"
    :placeholder="placeholder"
    :value.sync="selected"
    :items="savedInSessionRecommendations"
    :item-text="itemText"
    :item-value="itemValue"
    hideDetails
    required
    returnObject
  ></DefaultSelect>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    patient: {},
    session: {},
    label: {},
    placeholder: {},
    owner: {},
  },
  data() {
    return {
      recommendations: [],
    };
  },
  components: {
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    savedInSessionRecommendations() {
      const rec = [];
      this.recommendations.forEach((recommendation) => {
        if (recommendation.card.saved_in_session) {
          rec.push(recommendation);
        }
      });
      return rec;
    },
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.getHistoryField({
        name: "Recommendation",
        session: this.session.id,
        type: 0,
        patient: this.patient ? this.patient.id : undefined,
        owner: this.owner ? this.owner.id : undefined,
      })
        .then((reply) => {
          this.recommendations = reply;
          this.reset();
        })
        .catch(() => {
          this.handleError("Coś poszło nie tak.");
        });
    },
    itemText(item) {
      if (item.card) {
        return `Wizyta ${item.card.order}, ${item.card.date_time}`;
      }
      return `Wizyta`;
    },
    itemValue(item) {
      return item.id;
    },
  },
  created() {
    this.fetch();
  },
  mounted() {
    console.log(this.owner);
  }
};
</script>